<template>
  <!-- 兌獎紀錄 -->
  <div
    id="prize"
    v-scroll.self="onScroll"
    class="pt-56 pb-10 vh-100 overflow-y-auto"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 fixed bg z-index-1"
        >
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            background-color="bg"
            color="primary"
            grow
          >
            <template v-for="(item, index) in tabText">
              <v-tab
                v-show="showPrizeBonus && index === 0 || index !== 0"
                :key="index"
              >
                {{ item }}
              </v-tab>
            </template>
          </v-tabs>

          <!-- 快選日期 -->
          <v-row class="mt-1 px-2 py-2">
            <v-col
              v-for="(item, index) in dataBtnText"
              :key="index"
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
              :class="[
                dateBtn === index
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="changeData(...Object.keys(item), index)"
            >
              {{ item[Object.keys(item)] }}
            </v-col>
            <!-- 自定義 data -->
            <v-col
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
              :class="[
                dateBtn === 4
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="customDate"
            >
              {{ $t('myCenter.customize') }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        :class="[ dynamicStyleByClientLang ]"
      >
        <v-col
          cols="12"
          class="pb-2 pt-2"
        >
          <!-- 日期選取器 -->
          <Customize
            v-if="dateBtn === 4"
            @get-data="customDateGetRecord"
            @reset-config="resetNScroll"
          />

          <!-- 提醒說明文字 -->
          <p class="caption mb-0">
            {{ $t('myCenter.moreRecordMessage') }}
          </p>
        </v-col>

        <v-col
          v-if="form.type === 'bonus'"
          class="pb-0"
        >
          <!-- 篩選 subtype -->
          <v-btn
            text
            @click="dialog.status = true"
          >
            <!-- FILTER ICON -->
            <v-img
              width="24"
              height="24"
              src="../../assets/icons/filiterIcon.gif"
            />
            <span class="ml-1 title--text">
              {{ subTypeList.active }}
            </span>
          </v-btn>
        </v-col>

        <template v-if="recordData.length === 0">
          <v-col cols="12">
            <Nodata :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <!-- 若有資料 -->
        <template v-else>
          <v-col
            v-for="item in recordData"
            :key="item._id"
            cols="12"
            class="py-1 caption title--text"
          >
            <div class="card1 rounded pa-4 p-relative">
              <!------------------- tab 活動好禮 --------------------->
              <template v-if="tab === 0">
                <!-- 品名 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.prizeName') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.present_content }}
                  </span>
                </div>

                <!-- 類型 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.prizeType') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.present_type }}
                  </span>
                </div>
              </template>
              <!------------------- tab 活動好禮 --------------------->

              <!------------------- tab 紅利 --------------------->
              <template v-else-if="tab === 1">
                <!-- 單號 -->
                <div>
                  <span class="subtitle--text"> {{ $t('myCenter.no') }} </span>
                  <span
                    :id="item.order_id"
                    class="ml-4"
                  >
                    {{ item.order_id }}
                  </span>

                  <!-- copy BTM -->
                  <v-btn
                    icon
                    height="24"
                    width="24"
                    @click="copyNumber(item.order_id)"
                  >
                    <Icon
                      data="@icon/copy.svg"
                      width="16"
                      height="16"
                      class="subtitle--text"
                    />
                  </v-btn>
                </div>

                <!-- 金額 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('global.amount') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.amount }}
                  </span>
                </div>

                <!-- 紅利類型 -->
                <div class="pt-1">
                  <span class="subtitle--text">
                    {{ $t('myCenter.type') }}
                  </span>

                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.sub_type }}
                  </span>
                </div>
              </template>
              <!------------------- tab 紅利 --------------------->

              <!------------------- tab 推薦禮金 --------------------->
              <template v-else>
                <!-- 邀請好友 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedName') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_name }}
                  </span>
                </div>

                <!-- 首存累计存款 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedFirstDeposit') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_first_deposit }}
                  </span>
                </div>

                <!-- 返利奖励 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedRebate') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_rebate }}
                  </span>

                  <!-- 返利狀態 -->
                  <span class="ml-1">({{ item.invited_rebate_status }})</span>
                </div>

                <!-- 邀请礼金 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedRebateStatus') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_bonus }}
                  </span>
                </div>
              </template>
              <!------------------- tab 推薦禮金 --------------------->

              <!-- 日期 -->
              <div class="pt-1">
                <span class="subtitle--text">
                  <Icon
                    data="@icon/date.svg"
                    width="13"
                    height="13"
                  />
                </span>

                <!-- 如果是推薦禮金 tab -->
                <span
                  v-if="form.type === 'recommend'"
                  class="ml-3"
                >
                  {{ $day(item.create_date).format('YYYY/MM/DD') }}
                </span>

                <!-- 如果是 活動獎品 / 紅利 tab -->
                <span
                  v-else
                  class="ml-3"
                >
                  {{ $day(item.trans_at).format('YYYY/MM/DD HH:mm') }}
                </span>
              </div>

              <!-- 狀態 -->
              <v-btn
                outlined
                height="24"
                width="61"
                class="status-btn p-absolute caption"
                :color="statusColor(item.status)"
              >
                {{ $t(`global.${item.status}`) }}
              </v-btn>
            </div>
          </v-col>

          <!--  Bottom LOADING  -->
          <v-col
            v-show="scrollBottom.progress"
            cols="12"
            class="text-center pb-0"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- DIALOG -->
    <Select
      :status="dialog.status"
      :height="344"
      :width="280"
    >
      <v-row
        slot="title"
        class="p-sticky top-0 bg"
      >
        <!-- 選擇紅利類型 -->
        <v-col
          cols="12"
          class="text-h6 title--text px-6 py-4 p-sticky"
        >
          {{ $t('form.missType') }}
        </v-col>
      </v-row>

      <v-row slot="list">
        <v-col
          v-for="(item, index) in subTypeList.text"
          :key="index"
          cols="12"
          :class="['px-6', item === subTypeList.active ? 'secondary' : 'bg', 'rounded']"
          @click="setSubtypeActive(item)"
        >
          {{ item }}
        </v-col>
      </v-row>
    </Select>
  </div>
</template>

<script>
import Nodata from '@/components/base/Nodata.vue'
import Customize from './CustomizeDate'
import Select from '@/components/dialog/Select'

import { copyNumber } from '@/utils/copy'
import { timezone } from '@/utils/day'
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'

export default {
  components: { Nodata, Select, Customize },

  data: () => ({
    tab: 0,
    dateBtn: 0,

    form: {
      type: '', // 類型。bonus:紅利,recommend:推薦禮金,event:活動獎品
      sub_type: '', // 子類別，僅 type === bonus 適用
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '',
      page: 1,
    },

    recordData: [],

    subTypeList: {
      active: '',
      text: [],
      code: [],
    },

    dialog: {
      status: false,
    },

    scrollBottom: {
      status: true,
      ing: false,
      progress: true,
    },

    lastPage: null,
  }),

  computed: {
    ...mapState({
      // 顯示 活動獎品
      showPrizeBonus: state => state.websiteData.func_switch.redeem_event,
    }),

    ...mapGetters(['clientLang']),

    /**
     * tab 顯示文字
     * @date 2021-07-21
     */
    tabText() {
      return [
        this.$t('global.prizeBonus'),
        this.$t('global.bonus'),
        this.$t('global.recommendBonus'),
      ]
    },

    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    dynamicStyleByClientLang() {
      const widthAry = ['vi-VN', 'en', 'ph']
      const width = widthAry.includes(this.clientLang)
      return width ? 'pt-120' : 'pt-100'
    },
  },

  watch: {
    tab() {
      this.changeData(0, 0)
      this.subTypeList.active = this.$t('global.all')
    },

    'subTypeList.active'(newText) {
      this.form.sub_type = this.subTypeList.code[
        this.subTypeList.text.indexOf(newText)
      ]
      this.form.page = 1
      this.main(this.tab)
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('global.prize') + this.$t('global.record'))
    this.form.timezone = timezone()
    this.subTypeList.active = this.$t('global.all')
    this.setDate(0)
    // 如果不顯示「活動獎品」 tab
    if (!this.showPrizeBonus) this.tab = 1
  },

  methods: {
    ...mapActions([
      'get_redeem_record',
      'set_toolbar_title',
      'get_bonus_subtype',
      'show_alert',
    ]),

    /**
     * 功能入口，決定執行的 api
     * @date 2021-03-17
     * @param {any} nowIndex tab 索引，判斷執行的功能
     */
    main(nowIndex) {
      switch (nowIndex) {
        case 0:
          this.getRedeemLog('event')
          break

        case 1:
          this.getRedeemLog('bonus', this.form.sub_type)
          if (this.subTypeList.code.length === 0) this.getSubtypeData()
          break

        case 2:
          this.getRedeemLog('recommend')
          break

        default:
          break
      }
    },

    /**
     * 設置時間 (起始時間,結束時間)
     * @date 2021-03-17
     * @param {number}} dayNum 與今天相隔的天數
     */
    setDate(dayNum) {
      const today = this.$day().format('YYYY-MM-DD') // 今天日期
      const beforeDay = this.$day()
        .subtract(dayNum, 'day')
        .format('YYYY-MM-DD')

      // 如果為昨日
      if (dayNum === '1') {
        this.form.trans_at = `${beforeDay},${beforeDay}`
        return false
      }

      this.form.trans_at = `${beforeDay},${today}`
    },

    /**
     * 取得紀錄 (成功)
     * @date 2021-06-07
     * @param {object} data 紀錄資料
     */
    getRedeemLogSuccess({ logs }) {
      const resData = logs.data
      this.lastPage = logs.last_page

      // 第一次取資料
      if (this.form.page === 1) {
        this.recordData = []
        return (this.recordData = resData)
      }

      // 無資料
      if (resData.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.form.page = this.form.page - 1
        return (this.scrollBottom.ing = false)
      }

      // 如果是滾動加載
      setTimeout(() => {
        this.recordData = [...this.recordData, ...resData]
        this.scrollBottom.progress = false
      }, 800)
    },

    /**
     * 取得兌獎紀錄
     * @date 2021-03-19
     * @param {string}} type 兌獎類型 ex: 活動獎品 / 紅利 / 推薦禮金
     * @param {string} subtype ='all' 紅利類型的子項目
     */
    async getRedeemLog(type, subtype = 'all') {
      this.form.type = type
      this.form.sub_type = subtype
      const res = await this.get_redeem_record(this.form)
      resStatus(res, this.getRedeemLogSuccess)
    },

    /**
     * 快捷日期選擇功能
     * @date 2021-03-17
     * @param {number} dayNum 日期區間
     * @param {number} index 按鍵索引
     */
    changeData(dayNum, index) {
      this.resetNScroll()

      this.dateBtn = index
      this.setDate(dayNum)
      this.main(this.tab)
    },

    /**
     * 如果成功取得 getSubtypeData
     * @date 2021-03-18
     * @param {object}} data sub_type 相關資訊
     */
    getSubtypeDataSuccess(data) {
      // this.subtypeList = data.redeem_type
      const subtypeData = data.redeem_type
      const textAry = [this.$t('global.all')]
      const codeAry = ['all']

      subtypeData.forEach(item => {
        textAry.push(item.name)
        codeAry.push(item.sub_type)
      })
      this.subTypeList.text = textAry
      this.subTypeList.code = codeAry
    },

    /**
     * 取得 bonus 的 sub_type 列表
     * @date 2021-03-18
     */
    async getSubtypeData() {
      const bonusSubTypeList = await this.get_bonus_subtype()
      resStatus(bonusSubTypeList, this.getSubtypeDataSuccess)
    },

    statusColor(status) {
      const success = ['done', 'complete']
      const pending = ['wait', 'pending', 'verify']
      const fail = ['failed', 'close']

      if (success.includes(status)) return 'success'
      else if (pending.includes(status)) return 'warning'
      else return 'danger'
    },

    setSubtypeActive(subtype) {
      this.subTypeList.active = subtype
      this.dialog.status = false
    },

    copyNumber(id) {
      copyNumber(id)
    },

    /**
     * 使用自定義搜尋資料
     * @date 2021-04-28
     */
    customDate() {
      if (this.dateBtn === 4) return false
      this.changeData(0, 4)
    },

    /**
     * 自定義 取得資料方法
     * @date 2021-04-28
     * @param {string} timeRang 時間範圍 '2021-04-01,2021-04-28'
     */
    customDateGetRecord(timeRang) {
      this.form.trans_at = timeRang
      this.main(this.tab)
    },

    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.recordData = []
      this.form.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.form,
        scrollData: this.scrollBottom,
        func: () => this.main(this.tab),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (!isScrollToBottom(e)) {
        return false
      }

      // 已經是最末頁
      if (this.form.page >= this.lastPage) return false

      if (isScrollToBottom(e) && !this.scrollBottom.status) {
        if (this.scrollBottom.ing) return false
        return false
      }

      const bottom = this.setBottomFun()
      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-btn {
	right: 16px;
	bottom: 16px;
}

.top-0 {
	top: 0;
}

.pt-120{
  padding-top: 120px;
}
</style>
