<template>
  <!-- 交易紀錄 -->
  <div
    id="trade"
    v-scroll.self="onScroll"
    class="pt-56 pb-10 vh-100 overflow-y-auto"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 fixed bg z-index-1"
        >
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            background-color="bg"
            color="primary"
            grow
          >
            <v-tab
              v-for="(item, index) in tabText"
              :key="index"
              :class="[dynamicStyleByClientLang[0]]"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <!-- 快選日期 -->
          <v-row class="mt-1 px-2 py-2">
            <v-col
              v-for="(item, index) in dataBtnText"
              :key="index"
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 d-flex text-center justify-center align-center"
              :class="[
                dateBtn === index
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="changeData(...Object.keys(item), index)"
            >
              {{ item[Object.keys(item)] }}
            </v-col>
            <!-- 自定義 data -->
            <v-col
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 d-flex justify-center align-center text-center"
              :class="[
                dateBtn === 4
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="customDate"
            >
              {{ $t('myCenter.customize') }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        id="data"
        :class="[ dynamicStyleByClientLang[1] ]"
      >
        <v-col
          cols="12"
          class="pb-2 pt-2"
        >
          <!-- 日期選取器 -->
          <Customize
            v-if="dateBtn === 4"
            @get-data="customDateGetRecord"
            @reset-config="resetNScroll"
          />

          <!-- 提醒說明文字 -->
          <p class="caption mb-0">
            {{ $t('myCenter.moreRecordMessage') }}
          </p>
        </v-col>
        <template v-if="recordData.length === 0">
          <v-col cols="12">
            <Nodata :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <template v-else>
          <!-- 若有資料 -->
          <v-col
            v-for="item in recordData"
            :key="item._id"
            cols="12"
            class="py-1 caption"
          >
            <div class="card1 rounded pa-4 p-relative">
              <!-- 單號 -->
              <div>
                <span class="subtitle--text">
                  {{ $t('myCenter.no') }}
                </span>
                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.order_no }}
                </span>

                <!-- copy BTM -->
                <v-btn
                  icon
                  height="24"
                  width="24"
                  @click="copyNumber(item.order_no)"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="subtitle--text"
                  />
                </v-btn>
              </div>

              <!-- 金額 -->
              <div>
                <span class="subtitle--text">
                  {{ $t('global.amount') }}
                </span>
                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.status !== 'done' && item.expected_amount ? item.expected_amount : item.amount }}
                </span>
              </div>

              <!-- 取款 -->
              <div v-if="tab === 1">
                <!-- 帳戶 -->
                <span class="subtitle--text">
                  {{ $t('myCenter.bankAccount') }}
                </span>

                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.defray_name }}
                </span>
              </div>

              <!-- 交易類型 (存款 / 轉換) -->
              <div v-else>
                <span class="subtitle--text">
                  {{ $t('myCenter.tradeType') }}
                </span>

                <!-- FOR 存款 -->
                <template v-if="tab === 0">
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.paytype_name }}
                  </span>
                </template>

                <!-- FOR 額度轉換 -->
                <template v-if="tab === 2">
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.from_wallet }}
                    <Icon
                      data="@icon/arrow__right.svg"
                      width="16"
                      height="16"
                      class="mx-1 primary--text"
                    />
                    {{ item.to_wallet }}
                  </span>
                </template>
              </div>

              <!-- MEMO 備註 -->
              <div
                v-if="tab !== 2 && statusColor(item.status) === 'danger'"
                class="pt-1"
              >
                <!-- 備註 -->
                <span class="subtitle--text">
                  {{ $t('global.memo') }}
                </span>

                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.memo }}
                </span>
              </div>

              <!-- 日期 -->
              <div class="pt-1">
                <span class="subtitle--text">
                  <Icon
                    data="@icon/date.svg"
                    width="13"
                    height="13"
                    class="subtitle--text"
                  />
                </span>
                <span class="ml-3">
                  {{ $day(item.trans_at).format('YYYY/MM/DD HH:mm') }}
                </span>
              </div>

              <!-- 狀態 -->
              <v-btn
                outlined
                height="24"
                class="status-btn p-absolute caption"
                :color="statusColor(item.status)"
              >
                {{ $t(`global.${item.status}`) }}
              </v-btn>
            </div>
          </v-col>

          <!--  Bottom LOADING  -->
          <v-col
            v-show="scrollBottom.progress"
            cols="12"
            class="text-center pb-0"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Nodata from '@/components/base/Nodata.vue'
import Customize from './CustomizeDate'

import { timezone } from '@/utils/day'
import { mapActions, mapGetters } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { copyNumber } from '@/utils/copy'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'

export default {
  components: { Nodata, Customize },

  data: () => ({
    tab: '',
    dateBtn: 0,

    form: {
      trans_type: 'deposit', // 交易類型 deposit, withdrawal
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '',
      page: 1,
    },

    recordData: [],

    scrollBottom: {
      status: true,
      ing: false,
      progress: false,
    },

    lastPage: null,
  }),

  computed: {
    ...mapGetters(['clientLang']),

    tabText() {
      return [
        this.$t('global.deposit'),
        this.$t('global.withdraw'),
        this.$t('global.transfer'),
      ]
    },

    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    // 因應前端語系 layout 動態調整
    dynamicStyleByClientLang() {
      const widthAry = ['vi-VN', 'en', 'ph']
      const width = widthAry.includes(this.clientLang)
      return [
        { caption: width },
        width ? 'pt-120' : 'pt-100',
      ]
    },
  },

  watch: {
    tab() {
      this.changeData(0, 0)
    },
  },

  mounted() {
    this.set_toolbar_title(
      this.$t('global.transaction') + this.$t('global.record'),
    )

    this.form.timezone = timezone()
    this.setDate(0)
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_paymentLog',
      'get_transaction_record',
      'show_alert',
    ]),

    /**
     * 功能入口，決定執行的 api
     * @date 2021-03-17
     * @param {number} nowIndex tab 索引，判斷執行的功能
     */
    main(nowIndex) {
      switch (nowIndex) {
        case 0:
          this.getPaymentLog('deposit')
          break

        case 1:
          this.getPaymentLog('withdrawal')
          break

        case 2:
          this.getTransationRecord(
            'gameTransferIn,gameTransferOut,transferIn,transferOut,boDeposit,boWithdrawal',
          )
          break

        default:
          break
      }
    },

    /**
     * 設置時間 (起始時間,結束時間)
     * @date 2021-03-17
     * @param {number}} dayNum 與今天相隔的天數
     */
    setDate(dayNum) {
      const today = this.$day().format('YYYY-MM-DD') // 今天日期
      const beforeDay = this.$day()
        .subtract(dayNum, 'day')
        .format('YYYY-MM-DD')

      // 如果為昨日
      if (dayNum === '1') {
        this.form.trans_at = `${beforeDay},${beforeDay}`
        return false
      }

      const time = `${beforeDay},${today}`
      this.form.trans_at = time
    },

    /** (成功) 取得 存款/取款 紀錄
     * 描述
     * @date 2021-03-19
     * @param {any} data
     * @returns {any}
     */
    getPaymentLogSuccess({ logs }) {
      const data = logs.data
      this.lastPage = logs.last_page

      if (this.form.page === 1) {
        this.recordData = []
        return (this.recordData = data)
      }

      if (data.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.form.page = this.form.page - 1
        return (this.scrollBottom.ing = false)
      }

      // 如果是滾動加載
      setTimeout(() => {
        this.recordData = [...this.recordData, ...data]
        this.scrollBottom.progress = false
      }, 800)
    },

    /**
     * 取得 存款/取款 紀錄
     * @date 2021-03-17
     * @param {string}} trans_type 存款/取款
     */
    async getPaymentLog(trans_type) {
      this.form.trans_type = trans_type
      const res = await this.get_paymentLog(this.form)
      resStatus(res, this.getPaymentLogSuccess)
    },

    /**
     * 取得 額度轉換 紀錄
     * @date 2021-03-17
     * @param {string}} trans_type 存款/取款
     */
    async getTransationRecord(trans_type) {
      this.form.trans_type = trans_type
      const res = await this.get_transaction_record(this.form)
      resStatus(res, this.getPaymentLogSuccess)
    },

    /**
     * 快捷日期選擇功能
     * @date 2021-03-17
     * @param {number} dayNum 日期區間
     * @param {number} index 按鍵索引
     */
    changeData(dayNum, index) {
      this.resetNScroll()
      this.dateBtn = index
      this.setDate(dayNum)
      this.main(this.tab)
    },

    /**
     * 狀態 動態換色
     * @date 2021-04-28
     * @param {string} status 狀態
     */
    statusColor(status) {
      const success = ['done', 'complete']
      const pending = ['wait', 'pending', 'verify']
      const fail = ['failed', 'close', 'return']

      if (success.includes(status)) return 'success'
      else if (pending.includes(status)) return 'warning'
      else return 'danger'
    },

    copyNumber(id) {
      copyNumber(id)
    },

    /**
     * 使用自定義搜尋資料
     * @date 2021-04-28
     */
    customDate() {
      if (this.dateBtn === 4) return false
      this.changeData(0, 4)
    },

    /**
     * 自定義 取得資料方法
     * @date 2021-04-28
     * @param {string} timeRang 時間範圍 '2021-04-01,2021-04-28'
     */
    customDateGetRecord(timeRang) {
      this.form.trans_at = timeRang
      this.main(this.tab)
    },

    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.recordData = []
      this.form.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.form,
        scrollData: this.scrollBottom,
        func: () => this.main(this.tab),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (!isScrollToBottom(e)) {
        return false
      }

      if (this.form.page >= this.lastPage) return false

      if (isScrollToBottom(e) && !this.scrollBottom.status) {
        if (this.scrollBottom.ing) return false
        return false
      }

      const bottom = this.setBottomFun()
      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-btn {
	right: 16px;
	bottom: 16px;
}

.pt-120{
  padding-top: 120px;
}
</style>
